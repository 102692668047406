import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import App from './App';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueCookies);

Vue.use(VueAxios, axios);

Vue.use(VueMoment, {
  moment,
});

require('./plugins/vee-validate');

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
