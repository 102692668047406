export const debounce = (fn, delay) => {
  let timeout;

  return function (...args) {
    if (timeout) {
      clearTimeout(timeout);
    }

    const target = this;

    timeout = setTimeout(() => {
      fn.apply(target, args);
    }, delay);
  };
};

export const updateParams = (value, params) => {
  const valueHasLength = value.length > 0;
  const copyParams = { ...params };

  if (valueHasLength) {
    copyParams.search = value;
  } else {
    delete copyParams.search;
  }

  if (valueHasLength && params.page > 1) {
    copyParams.page = 1;
  }

  return copyParams;
};
