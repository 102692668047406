import { apiCall } from '@/services/axios';

export default {
  actions: {
    async GET_USER_PROFILE({ commit }) {
      await apiCall('get', 'users/get-profile')
        .then((res) => {
          commit('updateUserProfile', res.data.data);
          commit('updateLocaleInfo', res.data.data);
        })
        .catch((err) => console.log(err));
    },
    async UPDATE_USER_PROFILE({ commit }, data) {
      await apiCall('post', 'users/edit-profile', data, { 'Content-Type': 'multipart/form-data' })
        .then((res) => {
          if (res.data.is_success) {
            commit('updateUserProfile', res.data.data);
            commit('updateLocaleInfo', res.data.data);
            commit('addNewSnackBar', {
              text: 'Profile has been edited',
              status: null,
              show: true,
            });
            commit('updateProfileImgError', null);
          } else if (res.status === 422) {
            if (res.data?.email) {
              commit('addNewSnackBar', {
                text: res.data?.email,
                status: 'error',
                show: true,
              });
            }

            if (res.data.errors?.image[0]) {
              commit('updateProfileImgError', res.data.errors.image[0]);
            }
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: null,
              show: true,
            });
          }

          return res;
        })
        .catch((err) => console.log(err));
    },
    async RESET_USER_PHOTO({ commit }) {
      await apiCall('post', 'users/set-default-image')
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('updateLocalePhoto', res.data.data);
            commit('addNewSnackBar', {
              text: 'Photo has been removed',
              status: null,
              show: true,
            });
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: null,
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    UPDATE_USER_PASSWORD({ commit }, data) {
      return apiCall('post', 'users/change-password', data)
        .then((res) => {
          if (res.data.is_success) {
            commit('addNewSnackBar', {
              text: 'Password has been changed',
              status: 'success',
              show: true,
            });
          } else if (res.data.errors.current_password) {
            commit('addNewSnackBar', {
              text: res.data.errors.current_password[0],
              status: 'error',
              show: true,
            });
            commit('updateCurrentPasswordStatus', true);
          } else if (res.data.errors.password) {
            commit('addNewSnackBar', {
              text: res.data.errors.password[0],
              status: 'error',
              show: true,
            });
          }

          return res;
        })
        .catch((err) => console.log(err));
    },
  },
  mutations: {
    updateUserProfile(state, profile) {
      state.userProfile = profile;
      state.timezones = profile.timezones;
    },
    updateCurrentPasswordStatus(state, status) {
      state.currentPasswordStatus = status;
    },
    updateProfileImgError(state, status) {
      state.profileImgError = status;
    },
  },
  state: {
    userProfile: {},
    timezones: [],
    currentPasswordStatus: false,
    profileImgError: null,
  },
  getters: {
    getUserProfile(state) {
      return state.userProfile;
    },
    getCurrentPasswordStatus(state) {
      return state.currentPasswordStatus;
    },
    getProfileImgError(state) {
      return state.profileImgError;
    },
    getUserTimeZone(state) {
      return state.timezones.find((timezone) => timezone.selected);
    },
  },
};
