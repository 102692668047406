var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"default-workspace",attrs:{"alt":_vm.defaultName,"title":_vm.defaultName}},'div',attrs,false),on),[(!_vm.isOpenMenu)?[(_vm.defaultName)?_c('span',[_vm._v(" "+_vm._s(_vm.defaultName)+" ")]):_c('span',{staticStyle:{"opacity":".7"}},[_vm._v(" Select a workspace ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" keyboard_arrow_down ")])]:_c('v-text-field',{ref:"text-field",model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2)]}}]),model:{value:(_vm.isOpenMenu),callback:function ($$v) {_vm.isOpenMenu=$$v},expression:"isOpenMenu"}},[_c('v-list',[_c('div',{staticClass:"list-wrap"},[(_vm.filteredWorkspaces.length > 0)?_vm._l((_vm.filteredWorkspaces),function(workspace,idx){return _c('navigation-item',{key:idx,class:{'active-workspace': _vm.selectedWorkspace.slug === workspace.slug},attrs:{"title":workspace.name,"title-has-badge":_vm.selectedWorkspace.slug === workspace.slug},on:{"click":function($event){return _vm.changeWorkspace(workspace)}}},[_c('div'),(!workspace.is_default
              &&
              workspace.id !== _vm.selectedWorkspace.id
              &&
              _vm.superAdmin)?_c('div',{staticClass:"workspace-actions"},[_c('icon-button',{on:{"click":function($event){$event.stopPropagation();return _vm.editWorkspace(workspace)}}},[_c('v-icon',{staticClass:"grey--text"},[_vm._v(" drive_file_rename_outline ")])],1),(!workspace.has_activities)?_c('icon-button',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteWorkspace(workspace)}}},[_c('v-icon',{staticClass:"grey--text"},[_vm._v(" delete ")])],1):_vm._e()],1):_vm._e()])}):_c('div',{staticClass:"text-center"},[_vm._v(" No data available ")])],2),_vm._t("default")],2),_c('custom-dialog',{attrs:{"title":"Edit Workspace","btn-text":"Edit Workspace"},on:{"save":_vm.saveEditedWorkspace},model:{value:(_vm.editWorkspaceStatus),callback:function ($$v) {_vm.editWorkspaceStatus=$$v},expression:"editWorkspaceStatus"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('text-field',{attrs:{"label":"Name","full-size":"","outlined":"","maxlength":"150","rules":[_vm.rules.required, _vm.rules.size, _vm.rules.min]},model:{value:(_vm.formData.workspace.name),callback:function ($$v) {_vm.$set(_vm.formData.workspace, "name", $$v)},expression:"formData.workspace.name"}})],1)],1)],1),_c('confirm-modal',{attrs:{"title":"Delete workspace"},on:{"save":_vm.confirmDeleteWorkspace},model:{value:(_vm.confirmStatus),callback:function ($$v) {_vm.confirmStatus=$$v},expression:"confirmStatus"}},[_vm._v(" Are you sure you want to delete this workspace? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }