<template>
  <v-list-item
    :id="id"
    class="px-0 custom-list__item"
    :to="to"
    :href="href"
    @click="$emit('click')"
  >
    <v-list-item-icon v-if="icon" class="mt-0 mb-0 mr-4 ml-1">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content v-if="title" class="pt-0 pb-0">
      <badge
        v-if="titleHasBadge"
        :inline="true"
        :dot="true"
        :color="'#27AE60'"
        class="pl-4 justify-start"
      >
        <template v-slot:text>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </template>
      </badge>
      <v-list-item-title v-else :class="{'pl-4': !icon}">
        {{ title }}
      </v-list-item-title>
    </v-list-item-content>
    <slot />
  </v-list-item>
</template>

<script>

import Badge from '@/components/ui/Badge/Badge';

export default {
  name: 'NavigationItem',
  components: {
    Badge,
  },
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      default: undefined,
    },
    href: {
      type: String,
      default: undefined,
    },
    titleHasBadge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .custom-list__item {
    &:before {
      border-radius: 5px;
    }
  }

  .v-list-item--active {

    &:before {
      z-index: -1;
    }

    .v-list-item__icon {
      .v-icon {
        color: $warning;
      }
    }

    .v-list-item__content {
      .v-list-item__title {
        color: $white;
      }
    }
  }
</style>
