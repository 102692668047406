<template>
  <v-badge
    :inline="inline"
    :dot="dot"
    :color="color"
    v-bind="$attrs"
  >
    <slot name="text" />
  </v-badge>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    dot: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style scoped>

</style>
