import { getProjectTimerActivity, getRunningTimerActivity } from '@/api/timer';

export default {
  state: {
    timerActivity: {
      projectId: null,
      projectTime: null,
      globalTime: null,
      isRunningTimer: false,
    },
    isRunning: false,
    selectedTimerProject: {},
  },
  getters: {
    getTimerActivity: (state) => state.timerActivity,
    getTimerStatus: (state) => state.isRunning,
    getSelectedTimerProject: (state) => state.selectedTimerProject,
  },
  mutations: {
    SET_TIMER_ACTIVITY(state, data) {
      const {
        // eslint-disable-next-line camelcase
        project_id, global_timer, is_running, project_timer,
      } = data;
      state.timerActivity = {
        projectId: project_id,
        globalTime: global_timer,
        projectTime: project_timer,
        isRunningTimer: is_running,
      };
    },
    SET_TIMER_IS_RUNNING(state, value) {
      state.isRunning = value;
    },
    SET_SELECTED_TIMER_PROJECT(state, obj) {
      state.selectedTimerProject = obj;
    },
  },
  actions: {
    async getRunningTimerActivity({ commit }) {
      try {
        const { data } = await getRunningTimerActivity();
        commit('SET_TIMER_ACTIVITY', data);
      } catch (error) {
        console.log(error, 'catch error');
      }
    },
    async getProjectTimerActivity({ commit }, { id }) {
      try {
        const { data } = await getProjectTimerActivity(id);
        commit('SET_TIMER_ACTIVITY', data);
      } catch (error) {
        console.log(error, 'catch error');
      }
    },
  },
};
