import { apiCall } from '@/services/axios';

export default {
  actions: {
    async GET_COLORS({ commit }) {
      await apiCall('get', 'colors')
        .then((res) => {
          commit('updateColors', res.data.data);
        })
        .catch((err) => console.log(err));
    },
  },
  mutations: {
    updateColors(state, colors) {
      state.colors = colors;
    },
    updateLoader(state, status) {
      state.loaderStatus = status;
    },
  },
  state: {
    colors: [],
    loaderStatus: false,
  },
  getters: {
    getColors: (state) => state.colors,
    getLoaderStatus: (state) => state.loaderStatus,
  },
};
