import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=5ada667a&scoped=true&"
import script from "./Chip.vue?vue&type=script&lang=js&"
export * from "./Chip.vue?vue&type=script&lang=js&"
import style0 from "./Chip.vue?vue&type=style&index=0&id=5ada667a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ada667a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
