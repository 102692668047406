import { apiCall } from '@/services/axios';

export default {
  actions: {
    async GET_PROJECTS_FOR_NAVIGATION({ commit }, id) {
      await apiCall('get', `workspaces/${id}/projects/for-navigation`)
        .then((res) => {
          commit('updateNavigationProjects', res.data.data);

          // if (!state.selectedProject) {
          //   commit('updateSelectedProject',
          //   res.data.data.find((item) => item.is_default === true));
          // }
        })
        .catch((err) => console.log(err));
    },
    async GET_ACTIVE_PROJECTS({ commit }, data) {
      await apiCall('get', `workspaces/${data.id}/projects`, {}, '', data.params)
        .then((res) => {
          commit('updateProjects', res.data);
        })
        .catch((err) => console.log(err));
    },
    async GET_ARCHIVED_PROJECTS({ commit }, data) {
      await apiCall('get', `workspaces/${data.id}/projects`, {}, '', data.params)
        .then((res) => {
          commit('updateProjects', res.data);
        })
        .catch((err) => console.log(err));
    },
    async CREATE_NEW_PROJECT({ commit }, data) {
      await apiCall('post', `workspaces/${data.id}/projects/create`, data.project)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'New project has been created',
              status: 'success',
              show: true,
            });
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async SEARCH_PROJECT({ commit }, data) {
      await apiCall('post', `workspaces/${data.id}/projects/search`, data.search)
        .then((res) => {
          commit('updateProjects', res.data);
        })
        .catch((err) => console.log(err));
    },
    async ARCHIVE_PROJECTS({ commit, dispatch }, data) {
      await apiCall('post', `workspaces/${data.id}/projects/archive`, data.projects)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'Success',
              status: 'success',
              show: true,
            });
            dispatch('GET_PROJECTS_FOR_NAVIGATION', data.id);
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async DELETE_PROJECTS({ commit, dispatch }, data) {
      await apiCall('post', `workspaces/${data.id}/projects/delete`, data.projects)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'Success',
              status: 'success',
              show: true,
            });
            dispatch('GET_PROJECTS_FOR_NAVIGATION', data.id);
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async RESTORE_PROJECTS({ commit, dispatch }, data) {
      await apiCall('post', `workspaces/${data.id}/projects/restore`, data.projects)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'Success',
              status: 'success',
              show: true,
            });
            dispatch('GET_PROJECTS_FOR_NAVIGATION', data.id);
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async EDIT_PROJECT({ dispatch, commit }, data) {
      await apiCall('post', `workspaces/${data.id}/projects/${data.selectedProject}/edit`, data.project)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            dispatch('GET_ACTIVE_PROJECTS', {
              id: data.id,
              params: data.params,
            });
            commit('addNewSnackBar', {
              text: `Project ${data.project.name} has been edited`,
              status: 'success',
              show: true,
            });
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async GET_USER_PROJECTS({ commit }, params) {
      await apiCall('get', 'activities/get-member-projects', {}, '', params)
        .then((res) => {
          commit('updateUserProjects', res.data);
        })
        .catch((err) => console.log(err));
    },
  },
  mutations: {
    updateNavigationProjects(state, project) {
      state.navigationProjects = project;
    },
    updateProjects(state, project) {
      state.projects = project.data;
      state.projectsMeta = project.meta;
    },
    updateUserProjects(state, projects) {
      state.userProjets = projects;
    },
  },
  state: {
    navigationProjects: [],
    projects: [],
    projectsMeta: {},
    userProjets: {},
  },
  getters: {
    getProjectsWithCount(state) {
      return state.projects.map((item) => Object.assign(item, { count: 3 }));
    },
    getProjectsMeta(state) {
      return state.projectsMeta;
    },
    getProjects(state) {
      return state.projects;
    },
    getnavigationProjects(state) {
      return state.navigationProjects;
    },
    getUserProjects(state) {
      return state.userProjets;
    },
  },
};
