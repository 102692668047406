import { apiCall } from '@/services/axios';

export default {
  actions: {
    async GET_ALL_FOR_NAVIGATION({ commit }) {
      await apiCall('get', 'workspaces/for-navigation')
        .then((res) => {
          commit('updateWorkspaces', res.data.data);
          const { selectedWorkspace } = JSON.parse(localStorage.getItem('timeprism')).publicStore;

          if (selectedWorkspace === null) {
            commit('updateSelectedWorkspace', {});
          }

          // if (!selectedWorkspace) {
          //   commit('updateSelectedWorkspace',
          //   res.data.data.find((item) => item.id === selectedWorkspace?.id));
          // }
        })
        .catch((err) => console.log(err));
    },
    async CREATE_NEW_WORKSPACE({ dispatch, commit }, data) {
      await apiCall('post', 'workspaces/create', data)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('updateWorkspaces', res.data.data);
            commit('addNewSnackBar', {
              text: 'Workspace has been added',
              status: 'success',
              show: true,
            });
            dispatch('GET_ALL_FOR_NAVIGATION');
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async EDIT_WORKSPACE({ dispatch, commit }, data) {
      await apiCall('post', `workspaces/${data.id}/edit`, data)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'Workspace name has been edited',
              status: 'success',
              show: true,
            });
            dispatch('GET_ALL_FOR_NAVIGATION');
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async DELETE_WORKSPACE({ dispatch, commit }, data) {
      await apiCall('post', `workspaces/${data.id}/delete`)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: `Workspace ${data.name} has been deleted`,
              status: 'success',
              show: true,
            });
            dispatch('GET_ALL_FOR_NAVIGATION');
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async fetchPermissions({ commit }, id) {
      await apiCall('GET', `workspaces/permissions/${id}`)
        .then((response) => {
          commit('SET_PERMISSIONS', response.data);
        });
    },
  },
  mutations: {
    SET_PERMISSIONS(state, data) {
      state.permissions = data;
    },
  },
  state: {
    permissions: {},
  },
  getters: {
    getPermissions: (state) => state.permissions,
  },
};
