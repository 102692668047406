<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="account"
        v-on="on"
      >
        <v-avatar
          size="40"
        >
          <img :src="account.image" alt="">
        </v-avatar>
        <span>
          {{ account.name }}
        </span>
        <v-icon class="white-icon" small>
          keyboard_arrow_down
        </v-icon>
      </div>
    </template>
    <v-list>
      <slot />
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'AccountSelect',
  props: {
    account: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  ::v-deep.account {
    display: flex;
    align-items: center;
    overflow-x: hidden;

    .v-icon {
     position: absolute;
     margin-right: 6px;
     top: 50%;
     transform: translateY(-50%);
     right: 0;
    }
    span {
      white-space: nowrap;
      margin-left: $spacing-3;
      text-overflow: clip;
      max-width: 125px;
    }
  }
  ::v-deep.v-menu__content {
    min-width: 200px !important;
      .v-list-item {
        &--active {
          color: $black !important;
          .v-list-item__content .v-list-item__title{
            color: $black !important;
          }
        }
      }

  }
</style>
