<template>
  <v-dialog
    v-model="isOpen"
    :width="width"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <icon-button class="close" @click="cancel">
        <v-icon small>
          close
        </v-icon>
      </icon-button>
      <v-card-title class="font-weight-bold px-0 pt-0">
        {{ title }}
      </v-card-title>

      <div>
        <slot />
      </div>

      <v-card-actions class="px-0 mt-5">
        <text-button
          color="primary"
          text
          @click="cancel"
        >
          {{ btnText }}
        </text-button>
        <primary-button
          short
          @click="$emit('save', $event)"
        >
          Yes
        </primary-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import TextButton from '@/components/ui/Buttons/TextButton';
import IconButton from '@/components/ui/Buttons/IconButton';

export default {
  name: 'ConfirmModal',
  components: { IconButton, TextButton, PrimaryButton },
  props: {
    value: Boolean,
    width: {
      type: String,
      default: '440',
    },
    btnText: {
      type: String,
      default: 'No',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: this.value,
      valid: false,
    };
  },
  watch: {
    value(val) {
      this.isOpen = val;
    },
    isOpen(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    cancel() {
      this.isOpen = false;
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .v-card {
    border-radius: $radius-4;
    padding: 35px 40px 40px;
    position: relative;

    .v-card__title {
      /*margin-bottom: 32px;*/
    }

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
</style>
