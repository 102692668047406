<template>
  <div :class="['input-group']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InputGroup',
};
</script>

<style lang="scss" scoped>
.input-group {
  max-width: 280px;
  ::v-deep fieldset {
    height: 48px !important;
  }
}
</style>
