<template>
  <label
    class="input-label"
  >
    <slot />
    <slot name="append">
      <span v-if="required" class="error--text">*</span>
    </slot>
  </label>
</template>
<script>
export default {
  name: 'InputLabel',
  props: {
    required: Boolean,
  },
};
</script>
<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .input-label {
    font-size: $type-xs;
    margin-bottom: $spacing-2;
    display: block;
  }
</style>
