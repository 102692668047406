<template>
  <input-group
    :class="{'v-autocomplete__project': project}"
  >
    <input-label
      v-if="label"
      :for="$attrs.id"
      :required="required"
    >
      {{ label }}
      <template #append>
        <slot name="label-append" />
      </template>
    </input-label>
    <v-autocomplete
      append-icon=""
      class="autocomplete"
      :deletable-chips="deletableChips"
      :small-chips="smallChips"
      outlined
      :value="value"
      :item-text="itemText"
      :item-value="itemValue"
      :multiple="multiple"
      :rules="rules"
      v-bind="$attrs"
      v-on="$listeners"
      @input="$emit('input', value)"
      @update:search-input="useDebounce"
    >
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
        <slot :name="name" v-bind="scope" />
      </template>
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
      <template v-if="project" v-slot:item="{ item }">
        <v-icon
          x-small
          class="dot-icon mr-2"
          :style="{color: item.color_hex}"
        >
          fiber_manual_record
        </v-icon>
        <span
          style="
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap"
        >
          {{ item.name }}
        </span>
      </template>
      <template v-if="iconArrow" v-slot:append>
        <v-icon
          :class="arrowClass"
          small
        >
          keyboard_arrow_down
        </v-icon>
      </template>
      <template v-if="project" v-slot:selection="{ item }">
        <v-icon
          x-small
          class="dot-icon mr-2"
          :style="{color: item.color_hex}"
        >
          fiber_manual_record
        </v-icon>
        <span>
          {{ item.name }}
        </span>
      </template>
      <template
        v-else-if="multiple"
        v-slot:selection="{ attrs, item, index, select, selected }"
      >
        <chip
          v-if="index <= 1"
          close
          v-bind="attrs"
          :input-value="selected"
          @click:close="remove(index, item)"
        >
          <span>{{ item.name }}</span>
        </chip>
        <span
          v-if="index === 2"
          class="grey--text caption"
        >
          (+{{ value.length - index }} others)
        </span>
      </template>
    </v-autocomplete>
    <input-group />
  </input-group>
</template>

<script>
import InputGroup from '@/components/ui/InputGroup/InputGroup';
import InputLabel from '@/components/ui/InputLabel/InputLabel';
import Chip from '@/components/ui/Chips/Chip';
import { debounce } from '@/helpers';

export default {
  name: 'Autocomplete',
  components: { Chip, InputLabel, InputGroup },
  props: {
    project: {
      type: Boolean,
      default: false,
    },
    iconArrow: {
      type: Boolean,
      default: false,
    },
    arrowClass: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Array, Object, Number],
      default: undefined,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: undefined,
    },
    deletableChips: {
      type: Boolean,
      default: true,
    },
    smallChips: {
      type: Boolean,
      default: true,
    },
    multiple: Boolean,
    required: Boolean,
  },
  data() {
    return {
      chips: this.value,
      useDebounce: null,
    };
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.chips = val;
      },
    },
  },
  created() {
    this.useDebounce = debounce((value) => {
      if (value && value.length) {
        this.$emit('update-search-input', value);
      }
    }, 600);
  },
  methods: {
    remove(index, item) {
      this.chips.splice(index, 1);
      this.$emit('click:close', item);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  ::v-deep.input-group {
    max-width: unset;
  }

  .autocomplete {
    border-radius: $radius-3;

    ::v-deep.v-input__slot {
      min-height: 48px;
      padding: 0 8px !important;
      margin-bottom: 0;

      .v-input__append-inner {
        align-self: center;
        margin-bottom: 0;
        margin-top: -5px;
      }
    }
  }
  ::v-deep .v-select__selections {
    padding-top: 0 !important;
    flex-wrap: nowrap;
    .v-chip--select.v-chip {
      border-radius: unset;
      background-color: $primary;
      color: $white;
      height: 30px;
      .v-icon.v-chip__close {
        color: $white;
        &:after {
          display: none;
        }
      }
    }
  }
  ::v-deep .v-text-field .v-text-field__details {
    margin-bottom: 0;
  }
  ::v-deep .v-chip--disabled {
    opacity: 1;
  }
  ::v-deep .v-list-item__content{
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::v-deep.v-autocomplete__project {
    .v-select__selections {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 !important;
    }

    .v-select--is-menu-active {
      .v-select__selections {
        i {
          display: none;
        }
        span {
          display: none;
        }
      }
    }
  }
</style>
