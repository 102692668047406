<template>
  <v-btn
    depressed
    text
    :class="[
      {'clockyme-button__icon_white': white},
      {'clockyme-button__icon_error': error},
    ]"
    class="clockyme-button clockyme-button__icon"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: Boolean,
    white: Boolean,
    error: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/clockyme/buttons.scss";
</style>
