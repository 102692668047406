module.exports = {
  errorHandler(error) {
    const errorArray = [];

    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const key in error) {
      errorArray.push(error[key][0]);
    }

    return errorArray[0];
  },
};
