import { apiCall } from '@/services/axios';

export default {
  actions: {
    async GET_UNREAD_COUNT({ commit }) {
      await apiCall('GET', 'notifications/get-unread-count').then((result) => {
        commit('SET_UNREAD_COUNT', result.data.count);
      });
    },
    async GET_NOTIFICATIONS_LIST({ commit }, page = 1) {
      commit('SET_LOADING', true);
      await apiCall('GET', 'notifications/get-list', {}, {}, {
        page,
      }).then((result) => {
        commit('SET_NOTIFICATIONS_INFO', result.data);
        commit('SET_LOADING', false);
        commit('SET_PAGE_LOADING', false);
      });
    },
    async READ_ALL_NOTIFICATIONS({ commit }) {
      await apiCall('post', 'notifications/mark-as-read-all')
        .then((result) => {
          commit('SET_UNREAD_COUNT', result.data.unread_count);
          commit('READ_ALL_NOTIFICATIONS');
        });
    },
    async READ_CURRENT_NOTIFICATIONS({ commit }, ids) {
      await apiCall('post', 'notifications/mark-as-read', {
        notification_ids: ids,
      })
        .then((result) => {
          commit('SET_UNREAD_COUNT', result.data.unread_count);
          commit('READ_ALL_NOTIFICATIONS');
        })
        .catch((err) => console.log(err));
    },
  },
  mutations: {
    addNewSnackBar(state, bar) {
      const arr = state.snackBars;
      arr.push(bar);
      state.snackBars = arr;

      if (
        typeof bar.clearAfterTimeout === 'boolean'
        && bar.clearAfterTimeout === false
      ) return;

      setTimeout(() => {
        arr.shift();
        state.snackBars = arr;
      }, 2500);
    },
    removeSnackBar(state, index) {
      const arr = state.snackBars;
      arr.splice(index, 1);
      state.snackBars = arr;
    },
    clearSnackBars(state) {
      state.snackBars = [];
    },
    SET_UNREAD_COUNT(state, value) {
      state.unreadCount = value;
    },
    SET_LOADING(state, bool) {
      state.loading = bool;
    },
    SET_PAGE_LOADING(state, bool) {
      state.pageLoading = bool;
    },
    SET_NOTIFICATIONS_INFO(state, payload) {
      const { data } = state.notificationsInfo;
      const { meta } = payload;

      state.notificationsInfo = meta.current_page === 1
        ? { ...payload }
        : { ...payload, data: [...data, ...payload.data] };
    },
    READ_ALL_NOTIFICATIONS(state) {
      state.notificationsInfo.data = state.notificationsInfo.data
        .map((item) => ({ ...item, readed: true }));
    },
    CLEAR_STATE(state) {
      state.pageLoading = true;
      state.notificationsInfo = {
        data: [],
        ids: [],
        meta: {},
        links: {},
      };
    },
  },
  state: {
    snackBars: [],
    unreadCount: 0,
    pageLoading: true,
    loading: false,
    notificationsInfo: {
      data: [],
      ids: [],
      meta: {},
      links: {},
    },
  },
  getters: {
    getSnackBars(state) {
      return state.snackBars;
    },
    getUnreadCount(state) {
      return state.unreadCount;
    },
    getLoading(state) {
      return state.loading;
    },
    getPageLoading(state) {
      return state.pageLoading;
    },
    getNotificationsInfo(state) {
      return state.notificationsInfo;
    },
  },
};
