<template>
  <v-list class="snackbar-list">
    <v-list-item
      v-for="(bar, index) of items"
      :key="index"
    >
      <snack-bar-item
        :id="index"
        v-model="bar.show"
        :text="bar.text"
        :status="bar.status"
      />
    </v-list-item>
  </v-list>
</template>

<script>
import SnackBarItem from '@/components/ui/Snackbars/SnackBarItem';

export default {
  name: 'SnackBarsList',
  components: { SnackBarItem },
  props: {
    items: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.snackbar-list {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: unset;
  background-color: transparent;
  padding: 0;
  .v-list-item {
    padding: 0;
  }
}
</style>
