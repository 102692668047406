<template>
  <div class="mb-4">
    <autocomplete
      :value="selectedProject"
      class="project-autocomplete mb-4"
      label="Choose Project"
      item-text="name"
      item-value="id"
      return-object
      :items="projects"
      :deletable-chips="false"
      :small-chips="false"
      :hide-details="true"
      :icon-arrow="true"
      :project="true"
      :search-input.sync="searchValue"
      :menu-props="{
        maxWidth: 202,
        minWidth: 202
      }"
      @focus="searchValue = ''"
      @change="setSelectedProject"
    />
    <div class="d-flex">
      <timer-button
        :disabled="Object.keys(selectedProject).length === 0"
        :timer-status="timerStatus"
        @changeTimerStatus="changeTimerStatus"
      />
      <timer
        :get-timer-info="getTimerInfo"
        :timer-status="timerStatus"
      />
    </div>
  </div>
</template>
<script>
import TimerButton from '@/components/collections/TimerButton/TimerButton';
import Timer from '@/components/collections/Timer/Timer';
import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';
import { startTimer, stopTimer } from '@/api/timer';
import { apiCall } from '@/services/axios';
import { mapGetters } from 'vuex';

export default {
  name: 'TimerWidget',
  components: {
    TimerButton,
    Timer,
    Autocomplete,
  },
  data() {
    return {
      searchValue: '',
      selectedProject: {},
      timerStatus: false,
      projects: [],
    };
  },
  computed: {
    ...mapGetters(['getTimerActivity', 'getUserProfile', 'getSelectedWorkspace', 'getUserTimeZone']),
    getTimerInfo() {
      const { projectTime, globalTime } = this.getTimerActivity || {};

      return {
        project: projectTime > 0 && Object.keys(this.selectedProject).length > 0
          ? Number(projectTime) * 1000
          : 0,
        total: globalTime > 0
          ? Number(globalTime) * 1000
          : 0,
      };
    },
  },
  watch: {
    selectedProject(newProject, oldProject) {
      if (this.timerStatus && Object.keys(oldProject).length > 0) {
        this.updateTimerStatus(newProject, oldProject);
      }
    },
    getSelectedWorkspace: {
      handler: 'updateTimerAfterChangeSelect',
    },
    getUserTimeZone(timeZone) {
      if (timeZone) {
        this.resetTimerAfterMidnight(0, 1, 0, () => {
          this.$store.dispatch('getRunningTimerActivity').then(() => {
            this.getProjectsForTimer();
          });
        }, timeZone);
      }
    },
  },
  created() {
    this.$store.dispatch('getRunningTimerActivity').then(() => {
      this.getProjectsForTimer();
    });
  },
  methods: {
    async updateTimerAfterChangeSelect() {
      if (this.timerStatus) {
        await stopTimer(this.selectedProject.id).then(() => {
          this.timerStatus = false;
          this.selectedProject = {};
          this.$store.dispatch('getRunningTimerActivity');
          this.$store.commit('SET_TIMER_IS_RUNNING', false);
        });
      } else {
        this.$store.dispatch('getRunningTimerActivity');
        this.selectedProject = {};
      }

      this.getProjectsForTimer();
    },
    updateTimerStatus(newProject, oldProject) {
      this.timerStatus = false;
      this.showNotification(oldProject.name, false);
    },
    // TODO refactor axios create instance && settings axios interceptors
    async changeTimerStatus(timerIsStarted) {
      if (timerIsStarted) {
        await startTimer(this.selectedProject.id).then((response) => {
          if (response.status === 403 || response.status === 422) {
            this.showNotification(
              null,
              null,
              response.data.message || 'some error',
            );
          } else {
            this.showNotification(this.selectedProject.name, timerIsStarted);
          }

          this.timerStatus = timerIsStarted;
          this.$store.commit('SET_TIMER_IS_RUNNING', timerIsStarted);
        });
      } else {
        await stopTimer(this.selectedProject.id).then((response) => {
          if (response.status === 403 || response.status === 422) {
            this.showNotification(null, null, response.data.message);
          } else {
            this.showNotification(this.selectedProject.name, timerIsStarted);
          }

          this.timerStatus = timerIsStarted;
          this.$store.commit('SET_TIMER_IS_RUNNING', timerIsStarted);
        });
      }
    },
    setSelectedProject(project, isRunningTimer) {
      this.selectedProject = project;
      this.$store.commit('SET_SELECTED_TIMER_PROJECT', project);

      if (isRunningTimer) {
        this.timerStatus = true;
      } else {
        this.$store.dispatch('getProjectTimerActivity', { id: project.id });
      }
    },
    showNotification(selectedProjectName, timerIsStarted, errorText) {
      const text = errorText
        || `${timerIsStarted ? 'Started' : 'Stopped'} timer for project ${selectedProjectName}`;

      this.$store.commit('addNewSnackBar', {
        text,
        status: errorText ? 'error' : 'success',
        show: true,
      });
    },
    resetTimerAfterMidnight(hour, minutes, seconds, callbackFunc, timeZone) {
      const userTimeZone = timeZone.key === 'Europe/Kyiv' ? 'Europe/Kiev' : timeZone.key;
      const fullDay = 86400000;
      const nowDate = new Date();
      const dateWithUserTimeZone = this.$moment(this.$moment()
        .tz(userTimeZone)
        .format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm')
        .toDate();

      let actualMilliseconds = new Date(nowDate.getFullYear(),
        nowDate.getMonth(), nowDate.getDate(),
        hour, minutes, seconds, 0).getTime() - dateWithUserTimeZone;

      if (actualMilliseconds < 0) {
        actualMilliseconds += fullDay;
      }

      setTimeout(() => {
        callbackFunc();
        setInterval(callbackFunc, fullDay);
      }, actualMilliseconds);
    },
    async getProjectsForTimer() {
      const workspaceId = this.getSelectedWorkspace && this.getSelectedWorkspace.id;
      const projectsList = await apiCall('GET', `workspaces/${workspaceId}/projects/for-timer`);
      this.projects = projectsList.data;
      const { isRunningTimer, projectId } = this.getTimerActivity || {};
      const project = projectsList.data
        .find((el) => el.id === projectId);

      if (isRunningTimer) {
        this.setSelectedProject(project, true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";

::v-deep.project-autocomplete {
  max-width: 100%;

  .input-label {
    color: $white;
  }

  .v-input__slot {
    padding-left: 16px !important;

    fieldset {
      height: inherit !important;
    }
  }
}

</style>
