<template>
  <v-navigation-drawer
    app
    permanent
    :mini-variant.sync="mini"
    width="240"
    dark
    color="black"
    class="clock-me-navigation"
  >
    <v-list-item class="d-flex justify-space-between align-center pa-0 mb-5">
      <div class="d-flex align-center justify-space-between flex-grow-1">
        <router-link class="d-flex align-center" to="/dashboard">
          <img src="@/static/logo.svg">
        </router-link>
        <router-link class="notification-link" :to="buildPath('notification')">
          <v-icon>
            notifications
          </v-icon>
          <span v-if="getUnreadCountInfo.count > 0" class="badge__wrap">
            <badge
              :content="getUnreadCountInfo.content"
              :inline="true"
              color="red"
            />
          </span>
        </router-link>
      </div>
    </v-list-item>

    <v-list-item class="d-flex pa-0 account-item mb-4">
      <account-select
        :account="account"
      >
        <navigation-item
          title="Profile Settings"
          to="/settings"
        />
        <v-divider class="mx-4" />
        <navigation-item
          title="Log Out"
          @click="handleLogOut"
        />
      </account-select>
    </v-list-item>

    <v-list-item class="min-h-auto d-flex flex-column align-baseline pa-0 mb-4">
      <input-label class="text-grey">
        Workspace
      </input-label>
      <workspace-select
        :key="reload"
        :items="workspaces"
        @changeWorkspace="initialLoad"
      >
        <v-divider v-if="isSuperAdmin" class="mx-4" />
        <navigation-item
          v-if="isSuperAdmin"
          class="pl-3"
          @click="createWorkspaceStatus = true"
        >
          <v-icon class="primary-icon mr-2">
            add_circle
          </v-icon>
          Create Workspace
        </navigation-item>
      </workspace-select>
    </v-list-item>

    <navigation-item
      class="min-h-auto pt-2 pb-2 mb-4"
      title="Dashboard"
      icon="home"
      to="/dashboard"
    />

    <navigation-item
      v-if="isSelectedWorkspace"
      class="min-h-auto pt-2 pb-2 mb-4"
      title="Activity"
      icon="signal_cellular_alt"
      :to="buildPath('activity')"
    />

    <navigation-item
      v-if="isSelectedWorkspace"
      class="min-h-auto pt-2 pb-2 mb-4"
      title="Reports"
      icon="assignment"
      :to="buildPath('report')"
    />

    <div
      v-if="getPermissions['can_projects'] || getPermissions['can_members']"
      class="custom-title-block"
    >
      <span>
        Manage
      </span>

      <navigation-item
        v-if="getPermissions['can_projects']"
        class="min-h-auto pt-2 pb-2 mb-4"
        title="Projects"
        icon="source"
        :to="buildPath('project')"
      />

      <navigation-item
        v-if="getPermissions['can_members']"
        class="min-h-auto pt-2 pb-2 mb-4"
        title="Members"
        icon="person"
        :to="buildPath('member')"
      />
    </div>

    <v-divider class="mb-4" />

    <timer-widget v-if="isSelectedWorkspace && getPermissions['can_timer']" />

    <primary-button class="desktop-button">
      Desktop App
    </primary-button>

    <navigation-item
      class="min-h-auto pt-2 pb-2 mb-4"
      title="Profile Settings"
      icon="settings"
      to="/settings"
    />

    <navigation-item
      v-if="isSelectedWorkspace && getPermissions['can_workspace_settings']"
      class="min-h-auto pt-2 pb-2 mb-4"
      title="Workspace settings"
      icon="settings_applications"
      :to="buildPath('workspace-settings')"
    />

    <navigation-item
      class="min-h-auto pt-2 pb-2"
      title="Support"
      icon="help"
    />

    <custom-dialog
      v-model="createWorkspaceStatus"
      title="Creating Workspace"
      btn-text="Create Workspace"
      @save="createNewWorkspace"
    >
      <validation-observer ref="observer">
        <v-form ref="form">
          <v-row>
            <v-col md="12" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                name="name"
                rules="required|max:150|min:3"
              >
                <text-field
                  v-model="formData.workspace.name"
                  label="Name"
                  full-size
                  outlined
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </custom-dialog>
    <custom-dialog
      v-model="isOpenLogoutDialog"
      title="Your tracker is active now. Would you like to stop it and log out?"
      btn-text="Stop and log out"
      btn-cancel-text="No"
      @save="stopTimerAndLogout"
      @cancel="logOut"
    />
  </v-navigation-drawer>
</template>

<script>

import NavigationItem from '@/components/collections/MainNavigation/NavigationItem';
import AccountSelect from '@/components/collections/AccountSelect/AccountSelect';
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import WorkspaceSelect from '@/components/collections/WorkspaceSelect/WorkspaceSelect';
import CustomDialog from '@/components/collections/Dialog/CustomDialog';
import TextField from '@/components/ui/TextField/TextField';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import InputLabel from '@/components/ui/InputLabel/InputLabel';
import TimerWidget from '@/widgets/TimerWidget';
import Badge from '@/components/ui/Badge/Badge';
import { setActiveWorkspace } from '@/api';
import { stopTimer } from '@/api/timer';

export default {
  name: 'MainNavigation',
  components: {
    InputLabel,
    TextField,
    CustomDialog,
    WorkspaceSelect,
    PrimaryButton,
    AccountSelect,
    NavigationItem,
    TimerWidget,
    Badge,
  },
  data() {
    return {
      mini: false,
      selectedProject: {},
      projects: [],
      createWorkspaceStatus: false,
      editWorkspaceStatus: false,
      confirmStatus: false,
      formData: {
        workspace: {
          name: null,
          id: null,
        },
      },
      timerStatus: false,
      isOpenLogoutDialog: false,
      reload: 0,
    };
  },
  computed: {
    landingPath() {
      return `${process.env.VUE_APP_LANDING_URL}`;
    },
    ...mapGetters({
      account: 'getUserInfo',
      workspaces: 'getWorkspaces',
      selectedWorkspace: 'getSelectedWorkspace',
      isSuperAdmin: 'getSuperAdminStatus',
      getTimerStatus: 'getTimerStatus',
      getSelectedTimerProject: 'getSelectedTimerProject',
      getTimerActivity: 'getTimerActivity',
      getPermissions: 'getPermissions',
      getUnreadCount: 'getUnreadCount',
    }),
    getUnreadCountInfo() {
      return {
        content: this.getUnreadCount >= 100 ? `${99}+` : this.getUnreadCount,
        count: this.getUnreadCount,
      };
    },
    isSelectedWorkspace() {
      return this.selectedWorkspace === null ? false : !!Object.keys(this.selectedWorkspace).length;
    },
  },
  watch: {
    $route(to) {
      if (this.isSelectedWorkspace) {
        if (this.selectedWorkspace?.slug !== to.params.workspace) {
          const { workspaces } = JSON.parse(localStorage.getItem('timeprism')).publicStore;
          const workspaceItem = workspaces.find((item) => item.slug === to.params.workspace);

          if (workspaceItem) {
            this.updateSelectedWorkspace(workspaceItem);
            this.reload += 1;
          }

          this.$forceUpdate();
        }
      }
    },
    createWorkspaceStatus(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
  },
  mounted() {
    this.initialLoad();
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'updateSelectedWorkspace' && this.isSelectedWorkspace) {
        this.updateWorkspace(this.selectedWorkspace.id);
        this.setWorkspace(this.selectedWorkspace.id);
      }

      if (mutation.type === 'updateProjects') {
        this.getProjects();
      }
    });
  },
  methods: {
    ...mapActions(
      {
        updateWorkspace: 'GET_PROJECTS_FOR_NAVIGATION',
        getAllForNavigation: 'GET_ALL_FOR_NAVIGATION',
        getUserProfile: 'GET_USER_PROFILE',
        createWorkspace: 'CREATE_NEW_WORKSPACE',
      },
    ),
    ...mapMutations(
      {
        logOutMutation: 'updateAuthStatus',
        updateSelectedWorkspace: 'updateSelectedWorkspace',
        updateLoader: 'updateLoader',
      },
    ),
    async setWorkspace(id) {
      await setActiveWorkspace(id).then((response) => {
        this.$store.commit('SET_PERMISSIONS', response.data);
      });
    },
    async initialLoad() {
      this.updateLoader(true);
      setTimeout(async () => {
        await Promise.all([
          this.getAllForNavigation(),
          this.getUserProfile(),
          this.getProjects(),
        ]);
      }, 1000);

      setTimeout(() => {
        this.updateLoader(false);
      }, 1900);
    },
    buildPath(path) {
      return `/${this.selectedWorkspace?.slug}/${path}`;
    },
    handleLogOut() {
      if (this.getTimerStatus || this.getTimerActivity.isRunningTimer) {
        this.isOpenLogoutDialog = true;
      } else {
        this.logOut();
      }
    },
    stopTimerAndLogout() {
      return stopTimer(this.getSelectedTimerProject.id).then(() => {
        this.logOut();
      });
    },
    async logOut() {
      this.$store.commit('updateLoader', true);
      await setTimeout(() => {
        this.logOutMutation(false);
        this.$cookies.remove('timeprismToken');
        localStorage.removeItem('timeprism');
        document.location.replace(`${this.landingPath}`);
      }, 2500);
    },
    createNewWorkspace() {
      const observerRef = this.$refs.observer;
      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.createWorkspace(this.formData.workspace);
          this.formData = {
            workspace: {
              name: null,
              id: null,
            },
          };
          observerRef.reset();
          this.createWorkspaceStatus = false;
        }
      });
    },
    getProjects() {
      if (this.isSelectedWorkspace) {
        this.updateWorkspace(this.selectedWorkspace.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .clock-me-navigation {
    padding: $spacing-8 19px;
    overflow-y: hidden;
    ::v-deep.v-navigation-drawer__content::-webkit-scrollbar {
      display: none;
    }
    ::v-deep.v-navigation-drawer__content{
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .desktop-button {
    margin-bottom: $spacing-4;
  }

  .timer-block {
    display: flex;
    align-items: flex-end;
  }

  .notification {
    max-width: 360px;
    &.v-menu__content {
      .v-list {
        padding: 0;
        border-radius: 7px;
      }
    }
    &-item {
      padding: $spacing-2 $spacing-6;
      .v-list-item__title, .v-list-item__subtitle {
        flex: unset;
        &.notification-read-mark {
          margin-left: auto;
          margin-right: $spacing-5;
          color: $primary;
          font-size: $type-s;
          cursor: pointer;
        }
      }
      &.v-list-item--active {
        &:before {
          background-color: $white !important;
        }
      }
    }
    &-see-all {
      color: $primary;
      font-weight: $font-weight-medium;
      font-size: $type-s;
      cursor: pointer;
    }
    &-empty{
      text-align: center;
      text-transform: uppercase;
    }
    &-activator{
      position: relative;
    }
    &-new{
      position: absolute;
      right: -5px;
      bottom: -5px;
      background-color: $notification;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }
  }
  .account-item {
    position: relative;
  }

  .min-h-auto {
    min-height: auto;
  }

  .notification-link {
    position: relative;

    .v-icon {
      font-size: 35px;
    }

    ::v-deep.badge__wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .v-badge__badge {
        min-width: 18px;
        height: 18px;
        font-size: 10px;
        padding: 4px 4px;
      }
    }
  }

</style>
