var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('input-group',{class:{'v-autocomplete__project': _vm.project}},[(_vm.label)?_c('input-label',{attrs:{"for":_vm.$attrs.id,"required":_vm.required},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._t("label-append")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('v-autocomplete',_vm._g(_vm._b({staticClass:"autocomplete",attrs:{"append-icon":"","deletable-chips":_vm.deletableChips,"small-chips":_vm.smallChips,"outlined":"","value":_vm.value,"item-text":_vm.itemText,"item-value":_vm.itemValue,"multiple":_vm.multiple,"rules":_vm.rules},on:{"input":function($event){return _vm.$emit('input', _vm.value)},"update:search-input":_vm.useDebounce},scopedSlots:_vm._u([_vm._l((_vm.$slots),function(_,name){return {key:name,fn:function(){return [_vm._t(name)]},proxy:true}}),(_vm.project)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"dot-icon mr-2",style:({color: item.color_hex}),attrs:{"x-small":""}},[_vm._v(" fiber_manual_record ")]),_c('span',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}:null,(_vm.iconArrow)?{key:"append",fn:function(){return [_c('v-icon',{class:_vm.arrowClass,attrs:{"small":""}},[_vm._v(" keyboard_arrow_down ")])]},proxy:true}:null,(_vm.project)?{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"dot-icon mr-2",style:({color: item.color_hex}),attrs:{"x-small":""}},[_vm._v(" fiber_manual_record ")]),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}}:(_vm.multiple)?{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var index = ref.index;
var select = ref.select;
var selected = ref.selected;
return [(index <= 1)?_c('chip',_vm._b({attrs:{"close":"","input-value":selected},on:{"click:close":function($event){return _vm.remove(index, item)}}},'chip',attrs,false),[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.value.length - index)+" others) ")]):_vm._e()]}}:null],null,true)},'v-autocomplete',_vm.$attrs,false),_vm.$listeners)),_c('input-group')],1)}
var staticRenderFns = []

export { render, staticRenderFns }