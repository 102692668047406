<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="60000"
    right
  >
    <div class="snackbar-content">
      <img
        v-if="status === 'error'"
        class="snackbar-content-item"
        src="@/static/icons/snackbar-error.svg"
        alt=""
      >
      <img
        v-if="status === 'success'"
        class="snackbar-content-item"
        src="@/static/icons/snackbar-success.svg"
        alt=""
      >
      <div
        class="snackbar-content-item"
      >
        {{ text }}
      </div>
    </div>

    <template v-slot:action="{ attrs }">
      <icon-button
        v-bind="attrs"
        class="close-btn"
        @click="removeSnackBar(id)"
      >
        <v-icon class="black-icon">
          close
        </v-icon>
      </icon-button>
    </template>
  </v-snackbar>
</template>

<script>
import IconButton from '@/components/ui/Buttons/IconButton';

export default {
  name: 'SnackBarItem',
  components: { IconButton },
  props: {
    text: {
      type: String,
      default: null,
    },
    value: Boolean,
    status: {
      type: String,
      default: null,
    },
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      snackbar: this.value,
    };
  },
  watch: {
    value(val) {
      this.snackbar = val;
    },
  },

  methods: {
    removeSnackBar(index) {
      this.snackbar = false;
      this.$store.commit('removeSnackBar', index);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .v-snack {
    position: static;
    height: unset;
  }

  ::v-deep .v-snack__wrapper {
    background-color: white !important;
    color: $grey !important;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);
    border-radius: 10px 0 0 10px;
    margin-right: 0;
    padding-left: 16px;
    min-height: 56px;

    .v-snack__content {
      padding: 0;

      .snackbar-content {
        display: flex;
        align-items: center;
        &-item {
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
        }

      }
    }

    .close-btn {
      &:hover {
        &:before {
          background-color: $grey;
        }
      }
    }
  }
</style>
