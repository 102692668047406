export const redirectRoute = (path, name, redirect, options = {}) => ({
  path,
  name,
  redirect,
  ...options,
});

export const publicRoute = (path, name, component, options = {}) => ({
  path,
  name,
  component,
  ...options,
});

export const protectedRoute = (path, name, component, options = {}, metaTitle) => ({
  path,
  component,
  children: [
    {
      path,
      name,
      component,
    },
  ],
  meta: {
    requiresAuth: true,
    title: metaTitle,
  },
  ...options,
});
