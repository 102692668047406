import Vuetify from 'vuetify/lib';
import Vue from 'vue';
import CustomIcon from '@/components/ui/CustomIcon/CustomIcon';

Vue.use(Vuetify);
const customTheme = {
  primary: {
    base: '#574D92',
    lighten1: '#2775AE',
    lighten2: '#57ADEB',
  },
  secondary: {
    base: '#F2994A',
  },
  error: {
    base: '#EB5757',
  },
  info: '#2196F3',
  success: {
    base: '#27AE60',
  },
  warning: {
    base: '#FFD000',
  },
  black: {
    base: '#050024',
  },
  grey: {
    base: '#656375',
    lighten1: '#D4D3DB',
    lighten2: '#ECEBF0',
  },
};

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: customTheme,
      dark: customTheme,
    },
  },
  icons: {
    iconfont: 'md',
    values: CustomIcon,
  },
});
