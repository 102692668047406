import { apiCall } from '@/services/axios';
import cookie from 'vue-cookies';
import config from '@/config';
import { errorHandler } from '@/helpers/error-handler';

export default {
  actions: {
    async AUTH_CALL({ commit, dispatch }, authData) {
      await apiCall('post', 'login', authData)
        .then(async (res) => {
          if (res.status === 200) {
            if (!authData.remember) {
              cookie.set('timeprismToken', res.data.token, config.cookies.session);
            } else {
              cookie.set('timeprismToken', res.data.token, config.cookies.remember);
            }

            commit('updateToken', res.data.token);
            commit('updateErrorMsg', false);
            commit('updateAuthStatus', true);
            commit('updateUserInfo', res.data);
            commit('updateSelectedWorkspace', {});
            await dispatch('GET_ALL_FOR_NAVIGATION');
          } else if (res.status === 422) {
            if (res.data.errors) {
              commit('updateErrorMsg', errorHandler(res.data.errors));
            }
          }
        })
        .catch((err) => console.log(err));
    },
    async RECOVERY_PASSWORD({ commit }, data) {
      await apiCall('post', 'password/forgot', data)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'New password is sent to your email. If you don\'t see it, please check your spam folder',
              status: 'success',
              show: true,
              clearAfterTimeout: false,
            });
            commit('updateErrorMsg', false);
          } else if (res.status === 422) {
            if (res.data.errors) {
              commit('updateErrorMsg', errorHandler(res.data.errors));
            }
          }
          // commit('updateMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
  },
  mutations: {
    updateToken(state, token) {
      state.token = token;
    },
    updateAuthStatus(state, status) {
      state.isAuthorized = status;
    },
    updateErrorMsg(state, msg) {
      if (msg === 'Your credentials are wrong') {
        state.errorMsg = 'Incorrect login or password';

        return;
      }

      state.errorMsg = msg;
    },
    updateUserInfo(state, info) {
      state.user = info;
    },
    updateLocaleInfo(state, profile) {
      state.user.name = profile.name;
      state.user.image = profile.image;
    },
    updateLocalePhoto(state, photo) {
      state.user.image = photo.image;
    },
  },
  state: {
    isAuthorized: false,
    token: '',
    errorMsg: '',
    user: null,
  },
  getters: {
    getAuthStatus(state) {
      return state.isAuthorized;
    },
    getErrorMsg(state) {
      return state.errorMsg;
    },
    getUserInfo(state) {
      return state.user;
    },
    getSuperAdminStatus(state) {
      return state.user ? state.user.is_superadmin : false;
    },
  },
};
