<template>
  <v-chip
    close
    color="primary"
    @click="$emit('click', $event)"
    @click:close="$emit('click:close', $event)"
  >
    <slot />
  </v-chip>
</template>

<script>

export default {
  name: 'Chip',
};
</script>
<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .v-chip {
    border-radius: unset;
    background-color: $primary;
    color: $white;
    height: 30px;
    max-width: 80px;
    .v-icon.v-chip__close {
      color: $white;
      &:after {
        display: none;
      }
    }
    ::v-deep.v-chip__content {
      span {
        max-width: 60px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>
