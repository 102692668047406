import { apiCall } from '@/services/axios';

export default {
  actions: {
    // async GET_ACTIVITY({ dispatch, commit, getters }, { showAllActivities, params }) {
    //   // dispatch('GET_ACTIVE_PROJECTS', this.getSelectedWorkspace);
    //   if (getters.getAccessToRequest && showAllActivities) {
    //     dispatch('GET_ALL_ACTIVITY', params);
    //   } else if (getters.getAccessToRequest) {
    //     dispatch('GET_OWN_ACTIVITY', params);
    //   }
    // },
    // async GET_OWN_ACTIVITY({ commit }, params) {
    //   await apiCall('post', 'activities/get-own', params, '')
    //     .then((res) => {
    //       commit('updateData', res.data.data);
    //     })
    //     .catch((err) => console.log(err));
    // },
    async GET_ALL_ACTIVITY({ commit }, params) {
      commit('SET_DATA_LOADING', true);
      await apiCall('post', 'activities/get-member-activities', params, '')
        .then((res) => {
          commit('updateData', res.data.data);
          commit('SET_DATA_LOADING', false);
        })
        .catch((err) => console.log(err));
    },
    async GET_ACTIVITIES_FILTERS({ commit }, id) {
      commit('SET_FILTERS_LOADING', true);

      try {
        const { data } = await apiCall('GET', `activities/filters?workspace_id=${id}`);
        commit('SET_FILTERS_DATA', data);
        commit('SET_FILTERS_LOADING', false);
      } catch (error) {
        console.log(error);
      }
    },
    DELETE_ACTIVITY({ commit }, params) {
      return apiCall('post', 'activities/delete-own', { ...params }, '')
        .then(({ data: { data } }) => {
          commit('updateData', data);

          return data;
        })
        .catch((err) => console.log(err));
    },
  },
  mutations: {
    updateData(state, newData) {
      state.data = newData;
    },
    SET_FILTERS_LOADING(state, bool) {
      state.filtersLoading = bool;
    },
    SET_DATA_LOADING(state, bool) {
      state.loading = bool;
    },
    SET_FILTERS_DATA(state, data) {
      state.filtersData = {
        btns: data.btns,
        members: data.members,
        projects: data.projects,
      };
    },
  },
  state: {
    filtersLoading: false,
    loading: false,
    filtersData: {
      btns: {},
      members: [],
      projects: [],
    },
    data: {},
  },
  getters: {
    getFiltersData(state) {
      return state.filtersData;
    },
    getFiltersLoading(state) {
      return state.filtersLoading;
    },
    getData(state) {
      return state.data;
    },
    getDataLoading(state) {
      return state.loading;
    },
    getDailyTotal(state) {
      function secondsToHms(dig) {
        const d = Number(dig);
        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor(d % 3600 % 60);

        const hours = h > 9 ? h : `0${h}`;
        const minutes = m > 9 ? m : `0${m}`;
        const seconds = s > 9 ? s : `0${s}`;

        return `${hours}:${minutes}:${seconds}`;
      }

      return state.data && Object.keys(state.data).length > 0
        ? secondsToHms(state.data.total_worked)
        : '00:00:00';
    },
    getTotalActivity(state) {
      return state.data && Object.keys(state.data).length > 0
        ? `${Math.ceil(state.data.average_activity)}%`
        : '0%';
    },
    // getAccessToRequest(state) {
    //   return state.accessToRequest;
    // },
  },
};
