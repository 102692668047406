<template>
  <div
    :class="[
      {'white-dots': white},
      {'blue-dots': blue},
    ]"
    class="lds-ellipsis"
  >
    <div
      v-for="(item,index) in 4"
      :key="index"
      :style="color
        ? {backgroundColor: `${color} !important`} : {}"
    />
  </div>
</template>

<script>
export default {
  name: 'DotLoader',
  props: {
    white: Boolean,
    blue: Boolean,
    color: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .lds-ellipsis.blue-dots div {
    background-color: $primary !important;
  }

  .lds-ellipsis {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 36px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

</style>
