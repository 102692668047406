<template>
  <div class="main-wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Private',
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .main-wrap {
    background-color: $main-bg;
  }
</style>
