<template>
  <v-menu
    v-model="isOpenMenu"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="default-workspace"
        :alt="defaultName"
        :title="defaultName"
        v-on="on"
      >
        <template v-if="!isOpenMenu">
          <span v-if="defaultName">
            {{ defaultName }}
          </span>
          <span v-else style="opacity: .7">
            Select a workspace
          </span>
          <v-icon small>
            keyboard_arrow_down
          </v-icon>
        </template>
        <v-text-field
          v-else
          ref="text-field"
          v-model="search"
        />
      </div>
    </template>
    <v-list>
      <div class="list-wrap">
        <template v-if="filteredWorkspaces.length > 0">
          <navigation-item
            v-for="(workspace, idx) of filteredWorkspaces"
            :key="idx"
            :class="{'active-workspace': selectedWorkspace.slug === workspace.slug}"
            :title="workspace.name"
            :title-has-badge="selectedWorkspace.slug === workspace.slug"
            @click="changeWorkspace(workspace)"
          >
            <div />
            <div
              v-if="!workspace.is_default
                &&
                workspace.id !== selectedWorkspace.id
                &&
                superAdmin"
              class="workspace-actions"
            >
              <icon-button
                @click.stop="editWorkspace(workspace)"
              >
                <v-icon class="grey--text">
                  drive_file_rename_outline
                </v-icon>
              </icon-button>
              <icon-button
                v-if="!workspace.has_activities"
                @click.stop="deleteWorkspace(workspace)"
              >
                <v-icon class="grey--text">
                  delete
                </v-icon>
              </icon-button>
            </div>
          </navigation-item>
        </template>
        <div v-else class="text-center">
          No data available
        </div>
      </div>
      <slot />
    </v-list>
    <custom-dialog
      v-model="editWorkspaceStatus"
      title="Edit Workspace"
      btn-text="Edit Workspace"
      @save="saveEditedWorkspace"
    >
      <v-row>
        <v-col md="12" class="py-0">
          <text-field
            v-model="formData.workspace.name"
            label="Name"
            full-size
            outlined
            maxlength="150"
            :rules="[rules.required, rules.size, rules.min]"
          />
        </v-col>
      </v-row>
    </custom-dialog>
    <confirm-modal
      v-model="confirmStatus"
      title="Delete workspace"
      @save="confirmDeleteWorkspace"
    >
      Are you sure you want to delete this workspace?
    </confirm-modal>
  </v-menu>
</template>

<script>
import NavigationItem from '@/components/collections/MainNavigation/NavigationItem';
import IconButton from '@/components/ui/Buttons/IconButton';
import { mapGetters } from 'vuex';
import ConfirmModal from '@/components/collections/Dialog/ConfirmModal';
import CustomDialog from '@/components/collections/Dialog/CustomDialog';
import TextField from '@/components/ui/TextField/TextField';

export default {
  name: 'WorkspaceSelect',
  components: {
    TextField,
    CustomDialog,
    ConfirmModal,
    IconButton,
    NavigationItem,
  },
  props: {
    items: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      isOpenMenu: false,
      search: '',
      defaultName: null,
      confirmStatus: false,
      editWorkspaceStatus: false,
      workspaceForDelete: null,
      formData: {
        workspace: {
          name: '',
          id: null,
        },
      },
      rules: {
        size: (value) => (value.length <= 150)
          || 'The name field can contain a maximum of 150 characters',
        min: (value) => (value.length > 1)
          || 'The name field can contain a minimum of 2 characters',
        required: (value) => !!value || 'Required',
      },
    };
  },
  watch: {
    isOpenMenu(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs['text-field'].$refs.input.focus();
        });
      } else {
        this.search = '';
      }
    },
  },
  computed: {
    ...mapGetters({
      selectedWorkspace: 'getSelectedWorkspace',
      superAdmin: 'getSuperAdminStatus',
      getUserInfo: 'getUserInfo',
    }),
    defaultWorkspace() {
      return this.selectedWorkspace ? this.items.find((item) => item.id === this.selectedWorkspace?.id) : '';
    },
    filteredWorkspaces() {
      if (this.search.length > 0) {
        return [...this.items].filter((item) => item.name.toLowerCase()
          .includes(this.search.toLowerCase()));
      }

      return this.items;
    },
  },
  created() {
    if (!this.selectedWorkspace) {
      this.defaultName = this.defaultWorkspace.name;
    } else {
      this.defaultName = this.selectedWorkspace.name;
    }
  },

  methods: {
    async changeWorkspace(val) {
      this.$store.commit('updateSelectedWorkspace', val);
      this.$store.commit('updateLoader', true);

      this.defaultName = val.name;

      if (
        this.$route.params.workspace !== val.slug
        && this.$route.name !== 'Dashboard'
      ) {
        this.$router.push({ params: { workspace: val.slug } });
      }

      this.$store.commit('updateSelectedProject', null);

      setTimeout(() => {
        this.$store.commit('updateLoader', false);
      }, 1900);
    },
    resetData() {
      this.formData.workspace.name = '';
      this.formData.workspace.id = null;
    },
    deleteWorkspace(val) {
      this.confirmStatus = true;
      this.workspaceForDelete = val;
    },
    editWorkspace(val) {
      this.editWorkspaceStatus = true;
      this.formData.workspace = {
        name: val.name,
        id: val.id,
      };
    },
    confirmDeleteWorkspace() {
      this.$store.dispatch('DELETE_WORKSPACE', this.workspaceForDelete)
        .then(() => {
          this.workspaceForDelete = null;
          this.confirmStatus = false;
        });
    },
    saveEditedWorkspace() {
      this.$store.dispatch('EDIT_WORKSPACE', this.formData.workspace)
        .then(() => {
          this.resetData();
        });

      this.editWorkspaceStatus = false;
    },
    // filteredWorkspaceProjects(search) {
    //   console.log(search, 'search');
    // },
  },
};
</script>

<style lang="scss" scoped>

  @import "@/sass/_variables.scss";

  ::v-deep.default-workspace {
    border: 1px solid #656375;
    border-radius: $radius-3;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    color: $grey-light;
    font-size: $type-s;
    max-width: 200px;
    .v-icon {
      margin-left: auto;
    }
    span {
      max-width: 250px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
  .list-wrap {
    min-width: 270px;
    max-height: 250px;
    overflow-y: auto;
  }
  ::v-deep .v-list-item__title {
    max-width: 180px;
  }

  .workspace-actions {
    visibility: hidden;
    opacity: 0;
    margin-left: 10px;
    background: linear-gradient(270deg, #EBEBF0 69.27%, rgba(235, 235, 240, 0) 100%);
    z-index: 10;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 110px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .v-btn {
      &:hover {
        &:before {
          display: none;
        }
        .v-icon {
          color: $black !important;
        }
      }
    }
  }

  ::v-deep .v-list-item {
    position: relative;
    &.active-workspace {
      background-color: #ECEBF0 !important;
      .v-list-item__title  {
        color: $black;
      }
    }
    &:hover {
      background-color: #ECEBF0 !important;
      .v-list-item__title  {
        color: $black;
      }

      &:before {
        /*background-color: #ECEBF0 !important;*/
      }
      .workspace-actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }

</style>
