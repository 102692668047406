import Vue from 'vue';
import VueRouter from 'vue-router';
import { protectedRoute, publicRoute, redirectRoute } from '@/router/utils';
import cookie from 'vue-cookies';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    redirectRoute('/', 'Root', { name: 'Dashboard' }),
    publicRoute('*', 'Error', () => import(
      /* webpackChunkName: "Error" */ '@/views/Error404'
    ), {
      redirect: '/page-not-found',
    }),
    publicRoute('/', 'Public', () => import(
      /* webpackChunkName: "Public" */ '@/views/Public'
    ), {
      redirect: '/login',
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import(/* webpackChunkName: "Login" */ '@/views/PublicParts/Login'),
        },
        {
          path: '/password-recover',
          name: 'PasswordRecover',
          component: () => import(/* webpackChunkName: "PasswordRecover" */ '@/views/PublicParts/PasswordRecover'),
        },
      ],
    }),
    publicRoute('/invite/:code?', 'Invite', () => import(/* webpackChunkName: "Invite" */ '@/views/PublicParts/Invite')),
    publicRoute('/already-registered', 'AlreadyRegister', () => import(/* webpackChunkName: "AlreadyRegister" */ '@/views/PublicParts/AlreadyRegister')),
    publicRoute('/page-not-found', 'Error404', () => import(/* webpackChunkName: "Error" */ '@/views/Error404')),
    publicRoute('/access-denied', 'Error403', () => import(/* webpackChunkName: "Error" */ '@/views/Error403')),
    protectedRoute('/:workspace/activity', 'Activity', () => import(/* webpackChunkName: "Activity" */ '@/views/Activity/Activity')),
    protectedRoute('/:workspace/report/:id?',
      'Report',
      () => import(/* webpackChunkName: "Report" */ '@/views/Report/Report'),
      {
        redirect: '/:workspace/report/daily',
        children: [
          {
            path: 'daily',
            name: 'Daily',
            component: () => import(/* webpackChunkName: "Daily" */ '@/views/Report/Parts/Daily'),
          },
          {
            path: 'weekly',
            name: 'Weekly',
            component: () => import(/* webpackChunkName: "Weekly" */ '@/views/Report/Parts/Weekly'),
          },
          {
            path: 'chart',
            name: 'Chart',
            component: () => import(/* webpackChunkName: "Charts" */ '@/views/Report/Parts/Chart'),
          },
          {
            path: 'deleted',
            name: 'Deleted',
            component: () => import(/* webpackChunkName: "Deleted" */ '@/views/Report/Parts/Deleted'),
          },
        ],
      }),
    protectedRoute('/:workspace/notification', 'Notification', () => import(/* webpackChunkName: "Notification" */ '@/views/Notification/Notification')),
    protectedRoute('/:workspace/project/:id?',
      'Project',
      () => import(/* webpackChunkName: "Project" */ '@/views/Project/Project'),
      {
        redirect: '/:workspace/project/active',
        children: [
          {
            path: 'active',
            name: 'Active',
            component: () => import(/* webpackChunkName: "Active" */ '@/views/Project/Parts/Active'),
          },
          {
            path: 'archived',
            name: 'Archived',
            component: () => import(/* webpackChunkName: "Archived" */ '@/views/Project/Parts/Archived'),
          },
        ],
      }),
    protectedRoute('/:workspace/member/:id?', 'Member',
      () => import(/* webpackChunkName: "Member" */ '@/views/Member/Member'),
      {
        redirect: '/:workspace/member/active',
        children: [
          {
            path: 'active',
            name: 'ActiveMember',
            component: () => import(/* webpackChunkName: "ActiveMember" */ '@/views/Member/Parts/Active'),
          },
          {
            path: 'inactive',
            name: 'InactiveMember',
            component: () => import(/* webpackChunkName: "InactiveMember" */ '@/views/Member/Parts/Inactive'),
          },
          {
            path: 'unconfirmed',
            name: 'Unconfirmed',
            component: () => import(/* webpackChunkName: "Unconfirmed" */ '@/views/Member/Parts/Unconfirmed'),
          },
        ],
      }),
    protectedRoute('/dashboard',
      'Dashboard',
      () => import(/* webpackChunkName: "Settings" */ '@/views/Dashboard/Dashboard'), {}, 'Welcome to your Dashboard'),

    protectedRoute('/:workspace/workspace-settings/:id?',
      'WorkspaceSettings',
      () => import(/* webpackChunkName: "Settings" */ '@/views/WorkspaceSettings/WorkspaceSettings'),
      {
        redirect: {
          name: 'MemberRights',
        },
        children: [
          {
            path: 'member-rights',
            name: 'MemberRights',
            component: () => import(/* webpackChunkName: "MemberRights" */ '@/views/WorkspaceSettings/Parts/MemberRights'),
          },
          {
            path: 'roles',
            name: 'Roles',
            component: () => import(/* webpackChunkName: "Roles" */ '@/views/WorkspaceSettings/Parts/Roles'),
          },
        ],
      }),

    protectedRoute('/settings/:id?',
      'Settings',
      () => import(/* webpackChunkName: "Settings" */ '@/views/Settings/Settings'),
      {
        redirect: {
          name: 'Profile',
        },
        children: [
          {
            path: 'profile',
            name: 'Profile',
            component: () => import(/* webpackChunkName: "Profile" */ '@/views/Settings/Parts/Profile'),
          },
          {
            path: 'password',
            name: 'Password',
            component: () => import(/* webpackChunkName: "Password" */ '@/views/Settings/Parts/Password'),
          },
        ],
      }),
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.auth.isAuthorized) {
      next({
        path: '/login',
      });
      cookie.remove('timeprismToken');
    } else if (!cookie.get('timeprismToken')) {
      localStorage.removeItem('timeprism');
      next({
        path: '/login',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  const find = to.matched.find((item) => item.meta.title);

  if (find) {
    Vue.nextTick(() => {
      document.title = find.meta.title;
    });
  } else {
    Vue.nextTick(() => {
      document.title = 'timeprism';
    });
  }
});

export default router;
