<template>
  <i>{{ name }}</i>
</template>
<script>
export default {
  name: 'CustomIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
