<template>
  <v-btn
    depressed
    outlined
    class="clockyme-button clockyme-button__text"
    :class="[
      {'short-size': short},
      {'small-size': small},
    ]"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-if="!loading" />
    <dot-loader
      v-if="loading"
      :blue="blueLoader"
    />
  </v-btn>
</template>

<script>
import DotLoader from '@/components/ui/Loaders/DotLoader';

export default {
  name: 'TextButton',
  components: { DotLoader },
  props: {
    short: Boolean,
    small: Boolean,
    loading: Boolean,
    blueLoader: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/clockyme/buttons.scss";

  .short-size {
    max-width: 132px !important;
    height: 40px !important;
  }

  .small-size {
    max-width: 84px !important;
    height: 40px !important;
  }
</style>
