import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { max, min, email } from 'vee-validate/dist/rules';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend('required', {
  validate: (value) => {
    if (value === null || value === undefined || value === '') {
      return { valid: false };
    }

    return { valid: true };
  },
  message: 'Required',
});

extend('email', {
  ...email,
  message: 'Please, enter valid email address',
});

extend('max', {
  ...max,
  message: 'The field can contain a maximum of {length} characters',
});

extend('min', {
  ...min,
  message: 'The field can contain a minimum of {length} characters',
});

// {_field_}

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
