<template>
  <v-btn
    depressed
    outlined
    class="clockyme-button clockyme-button__timer"
    :class="{'active-timer' : active}"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
    @click="updateTimerStatus"
  >
    <v-icon v-if="!active">
      play_arrow
    </v-icon>
    <v-icon v-else>
      pause
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TimerButton',
  props: {
    timerStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedProject: 'getSelectedProject',
    }),
  },
  watch: {
    timerStatus(value) {
      this.active = value;
    },
  },

  methods: {
    updateTimerStatus() {
      if (this.getSelectedProject) {
        this.active = !this.active;
        this.$emit('changeTimerStatus', this.active);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/clockyme/buttons.scss";

  .clockyme-button__timer {
    &:disabled{
      opacity: .4;
    }
  }
</style>
