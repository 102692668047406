import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import workspaces from '@/store/modules/workspaces';
import notifications from '@/store/modules/notifications';
import profile from '@/store/modules/profile';
import projects from '@/store/modules/projects';
import publicStore from '@/store/modules/publicStore';
import tools from '@/store/modules/tools';
import members from '@/store/modules/members';
import auth from './modules/auth';
import activities from './modules/activities';
import timer from './modules/timer';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    workspaces,
    notifications,
    profile,
    projects,
    publicStore,
    tools,
    members,
    activities,
    timer,
  },
  plugins: [createPersistedState({
    key: 'timeprism',
    paths: ['auth', 'publicStore'],
  })],
});
