import { apiCall } from '@/services/axios';

export const getRunningTimerActivity = () => apiCall('GET', 'activities/timer');
export const getProjectTimerActivity = (id) => apiCall('POST', 'activities/timer/switch', {
  project_id: id,
  source: 'web',
});
export const startTimer = (id) => apiCall('POST', 'activities/timer/start', {
  project_id: id,
  source: 'web',
});
export const stopTimer = (id) => apiCall('POST', 'activities/timer/stop', {
  project_id: id,
  source: 'web',
});
