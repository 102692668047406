<template>
  <v-btn
    depressed
    outlined
    class="clockyme-button clockyme-button__primary"
    :class="[
      {'short-size': short},
      {'normal-size': normal},
      {'full-width': fullWidth},
    ]"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-if="!loading" />
    <dot-loader
      v-if="loading"
      white
    />
  </v-btn>
</template>

<script>
import DotLoader from '@/components/ui/Loaders/DotLoader';

export default {
  name: 'PrimaryButton',
  components: { DotLoader },
  props: {
    short: Boolean,
    normal: Boolean,
    fullWidth: Boolean,
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/clockyme/buttons.scss";

  .short-size {
    max-width: 132px !important;
  }

  .normal-size {
    max-width: 150px !important;
  }

  .full-width {
    max-width: unset !important;
  }

  .clockyme-button__primary{
    &:disabled {
      color: #fff !important;
      opacity: 0.8;
    }
  }
</style>
