<template>
  <input-group
    :class="[
      {'full-size': fullSize},
    ]"
  >
    <input-label
      v-if="label"
      :for="$attrs.id"
      :required="required"
    >
      {{ label }}
      <template #append>
        <slot name="label-append" />
      </template>
    </input-label>
    <v-text-field
      :color="color"
      :dense="dense"
      :class="[
        {'large': large},
        {'warning-text': color === 'warning'},
        {'success-text': color === 'success'},
      ]"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
        <slot :name="name" v-bind="scope" />
      </template>
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
    </v-text-field>
  </input-group>
</template>
<script>
import InputLabel from '@/components/ui/InputLabel/InputLabel';
import InputGroup from '@/components/ui/InputGroup/InputGroup';

export default {
  name: 'TextField',
  components: { InputGroup, InputLabel },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: true,
    },
    required: Boolean,
    large: Boolean,
    fullSize: Boolean,
    color: {
      type: String,
      default: undefined,
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  ::v-deep .v-input__control {
    fieldset {
      border-radius: $radius-3 !important;
    }
  }

  .large {
    ::v-deep .v-input__control {
      min-width: 400px !important;
    }
  }

  ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-left: 0;
    margin-top: $spacing-1;
  }

  .warning-text {
    ::v-deep.v-messages__message {
      color: $secondary;
    }
  }

  .success-text {
    ::v-deep.v-messages__message {
      color: $success;
    }
  }
  ::v-deep.input-group.full-size {
   max-width: unset !important;
  }
  ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
</style>
