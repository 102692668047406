import { apiCall } from '@/services/axios';
import cookie from 'vue-cookies';
import config from '@/config';

export default {
  actions: {
    async GET_ACTIVE_MEMBERS({ commit }, data) {
      await apiCall('get', `workspaces/${data.id}/member-cards`, {}, '', data.params)
        .then((res) => {
          commit('updateMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
    async GET_MEMBER_CARDS_IN_WORKSPACE({ commit }, params) {
      await apiCall('post', 'activities/get-member-cards-in-workspace', params, '')
        .then((res) => {
          commit('updateMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
    async GET_INACTIVE_MEMBERS({ commit }, data) {
      await apiCall('get', `workspaces/${data.id}/member-cards`, {}, '', data.params)
        .then((res) => {
          commit('updateMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
    async GET_PROJECT_MEMBERS({ commit }, data) {
      await apiCall('get', `workspaces/${data.id}/projects/${data.project}/get-member-cards`, {}, '', data.params)
        .then((res) => {
          commit('updateProjectMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
    async GET_UNCONFIRMED_MEMBERS({ commit }, data) {
      await apiCall('get', `workspaces/${data.id}/member-cards/get-unconfirmed`, {}, '', data.params)
        .then((res) => {
          commit('updateMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
    async SEARCH_UNCONFIRMED_MEMBERS({ commit }, data) {
      await apiCall('post', `workspaces/${data.id}/search-unconfirmed-members`, data.search)
        .then((res) => {
          commit('updateMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
    async SEARCH_INACTIVE_MEMBERS({ commit }, data) {
      await apiCall('post', `workspaces/${data.id}/search-members`, data.search)
        .then((res) => {
          commit('updateMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
    async SEARCH_ACTIVE_MEMBERS({ commit }, data) {
      await apiCall('post', `workspaces/${data.id}/search-members`, data.search)
        .then((res) => {
          commit('updateMembers', res.data);
        })
        .catch((err) => console.log(err));
    },
    async INVITE_MEMBER({ commit }, data) {
      await apiCall('post', `workspaces/${data.id}/member-cards`, data.member)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'Invite has been send',
              status: 'success',
              show: true,
            });
          } else if (res.status === 422) {
            if (res.data.errors?.invited_email[0]) {
              commit('addNewSnackBar', {
                text: res.data.errors.invited_email[0],
                status: 'error',
                show: true,
              });
            }
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async EDIT_MEMBER({ commit }, data) {
      await apiCall('post', `workspaces/${data.id}/member-cards/${data.memberId}`, data.member)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'Member has been edit',
              status: 'success',
              show: true,
            });
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async DELETE_MEMBERS({ commit }, data) {
      await apiCall('post', `workspaces/${data.id}/member-cards/delete`, data.members)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'Members has been delete',
              status: 'success',
              show: true,
            });
          } else {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    CONFIRM_INVITE({ commit, dispatch }, data) {
      return apiCall('post', 'member-cards/confirm', data.formData, '')
        // eslint-disable-next-line consistent-return
        .then(async (res) => {
          if (res.status === 200 || res.status === 201) {
            cookie.set('timeprismToken', res.data.user.token, config.cookies.remember);
            commit('updateToken', res.data.user.token);
            commit('updateErrorMsg', false);
            commit('updateAuthStatus', true);
            commit('updateUserInfo', res.data.user);
            await dispatch('GET_ALL_FOR_NAVIGATION');

            return res.data;
          }

          if (res.status === 422) {
            commit('updateInviteStatus', false);
          }
        })
        .catch((err) => console.log(err));
    },
    async CHECK_INVITE_STATUS({ commit }, data) {
      await apiCall('get', 'member-cards/confirm', {}, '', data.params)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('updateInviteStatus', true);
          } else if (res.status === 422) {
            commit('updateInviteStatus', false);
          }
        })
        .catch((err) => console.log(err));
    },
    async RESEND_INVITE({ commit }, data) {
      await apiCall('get', `workspaces/${data.id}/member-cards/${data.memberId}/resend-invitation`, {}, '', data.params)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('addNewSnackBar', {
              text: 'Invitation was resend successfully ',
              status: 'success',
              show: true,
            });
          } else if (res.status === 422) {
            commit('addNewSnackBar', {
              text: 'Something wrong',
              status: 'error',
              show: true,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    GET_INVITE_INFO({ commit }, data) {
      return apiCall('get', 'member-cards/get-info-by-code', {}, '', data.params)
        // eslint-disable-next-line consistent-return
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            commit('updateInviteInfo', res.data);
            commit('updateInviteStatus', true);
          }

          if (res.status === 422) {
            commit('updateInviteStatus', false);
          }

          return res.data;
        })
        .catch((err) => err);
    },
  },
  mutations: {
    updateMembers(state, project) {
      state.members = project.data;
      state.membersMeta = project.meta;
    },
    updateProjectMembers(state, project) {
      state.projectMembers = project.data;
      state.projectMembersMeta = project.meta;
    },
    updateInviteStatus(state, status) {
      state.inviteStatus = status;
    },
    updateInviteInfo(state, info) {
      console.log(info);
      state.inviteInfo = info;
    },
    updateActiveMember(state, newMember) {
      state.activeMember = newMember;
    },
  },
  state: {
    members: [],
    membersMeta: {},
    projectMembers: [],
    projectMembersMeta: [],
    inviteStatus: true,
    inviteInfo: null,
    activeMember: {},
  },
  getters: {
    getMembersMeta(state) {
      return state.membersMeta;
    },
    getMembers(state) {
      return state.members;
    },
    getProjectMembers(state) {
      return state.projectMembers;
    },
    getProjectMembersMeta(state) {
      return state.projectMembersMeta;
    },
    getInviteStatus(state) {
      return state.inviteStatus;
    },
    getInviteInfo(state) {
      return state.inviteInfo;
    },
    getActiveMember(state) {
      return state.activeMember;
    },
  },
};
