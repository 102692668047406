import Axios from 'axios';
import store from '@/store';
import router from '@/router';
import cookie from 'vue-cookies';

export async function apiCall(method, url, dataObj, head, params) {
  let data;
  const headers = {
    Authorization: `Bearer ${cookie.get('timeprismToken')}`,
  };

  try {
    data = await Axios({
      method,
      url: `${process.env.VUE_APP_BACKEND_URL}/${url}`,
      data: dataObj,
      headers: {
        ...headers,
        head,
      },
      params,
    });

    return data;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        store.commit('updateAuthStatus', false);
        store.commit('updateErrorMsg', error.response.data.message);

        await router.push('/login');

        break;
      case 423:
        store.commit('updateSelectedWorkspace', error.response.data.workspace);
        window.location.href = `${window.location.origin}/dashboard`;
        break;
      default:
        break;
    }

    return error.response;
  }
}
