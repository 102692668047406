<template>
  <v-dialog
    v-model="isOpen"
    :width="width"
    v-bind="$attrs"
    :scrollable="true"
    v-on="$listeners"
  >
    <v-card class="custom-v__card">
      <icon-button class="close" @click="isOpen = false">
        <v-icon small>
          close
        </v-icon>
      </icon-button>
      <v-card-title
        class="font-weight-bold pt-0"
        style="padding-left: 10px; word-break: initial"
      >
        {{ title }}
      </v-card-title>
      <div v-if="projectTitle" class="project-title">
        <span>Project: {{ projectTitle }}</span>
      </div>
      <v-skeleton-loader
        v-if="contentLoad"
        class="mt-5"
        type="text, heading, text, heading, text, heading, text, heading, actions"
      />
      <div v-else class="card-content">
        <div>
          <slot />
        </div>

        <v-card-actions v-if="!hideActions" class="mt-5 px-0 pb-0 pr-0">
          <primary-button
            normal
            :loading="loading"
            :disabled="disabledSubmitBtn"
            @click="$emit('save', $event)"
          >
            {{ btnText }}
          </primary-button>
          <text-button
            color="primary"
            text
            @click="handleCancel"
          >
            {{ btnCancelText }}
          </text-button>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import TextButton from '@/components/ui/Buttons/TextButton';
import IconButton from '@/components/ui/Buttons/IconButton';

export default {
  name: 'CustomDialog',
  components: { IconButton, TextButton, PrimaryButton },
  props: {
    value: Boolean,
    width: {
      type: String,
      default: '440',
    },
    btnText: {
      type: String,
      default: 'Save Changes',
    },
    btnCancelText: {
      type: String,
      default: 'Cancel',
    },
    title: {
      type: String,
      default: '',
    },
    projectTitle: {
      type: String,
      default: '',
    },
    loading: Boolean,
    hideActions: Boolean,
    contentLoad: Boolean,
    disabledSubmitBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.value,
      valid: false,
    };
  },
  watch: {
    value(val) {
      this.isOpen = val;
    },
    isOpen(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    handleCancel(event) {
      this.isOpen = false;
      this.$emit('cancel', event);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  ::v-deep .v-skeleton-loader__text.v-skeleton-loader__bone {
    margin-bottom: 20px;
    max-width: 100px;
    border-radius: $radius-3;
    height: 16px;
  }
  ::v-deep .v-skeleton-loader__heading.v-skeleton-loader__bone {
    margin-bottom: 20px;
    width: 90%;
    height: 48px;
    border-radius: $radius-3;
  }

  ::v-deep .v-skeleton-loader__actions.v-skeleton-loader__bone {
    justify-content: flex-start;
    display: flex;
    padding-left: 0;
    .v-skeleton-loader__button {
      width: 100px;
      height: 40px;
    }
  }

  ::v-deep .v-dialog {
    overflow: hidden;
  }
  .v-card {
    border-radius: $radius-4;
    padding: 35px 40px 40px;
    position: relative;

    .card-content {
      padding: 20px 10px 0 10px;
      max-height: 570px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .v-card__title {
      /*margin-bottom: 32px;*/
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .custom-v__card {
    padding-left: 30px;
    padding-right: 30px;
  }
  .project-title{
    color: #858585;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
  }
</style>
