export default {
  mutations: {
    updateSelectedWorkspace(state, workspace) {
      state.selectedWorkspace = workspace;
    },
    updateSelectedProject(state, project) {
      state.selectedProject = project;
    },
    updateWorkspaces(state, space) {
      state.workspaces = space;
    },
  },
  state: {
    selectedWorkspace: null,
    selectedProject: {
      id: null,
      name: 'all',
    },
    workspaces: [],
  },
  getters: {
    getSelectedWorkspace(state) {
      return state.selectedWorkspace;
    },
    getSelectedProject(state) {
      return state.selectedProject ? state.selectedProject : { id: null };
    },
    getWorkspaces(state) {
      return state.workspaces;
    },
  },
};
